header > img {
  float: left;
  margin-top: 20px;
  margin-left: 20px;
  width: 120px;
}

header {
  background-color: #17203b;
  height: 100px;
  width: 100%;
}

nav {
  float: right;
  margin-top: 25px;
  margin-right: 40px;
  width: 20%;
}

.intro {
  width: 100%;
  background-color: #10489c;
  border-bottom: 10px solid #2297da;
  padding: 80px 10%;
  font-family: georgia;
}

.intro h1 {
  font-size: 90px;
  color: #fff;
  font-style: italic;
  line-height: 1.5em;
}

.intro p {
  color: #2297da;
  font-size: 65px;
  line-height: 1.5em;
  letter-spacing: 0.05em;
}

.intro span {
  display: block;
  width: 90%;
  color: #49b4f1;
  font-size: 40px;
  line-height: 1em;
  text-align: right;
  margin-bottom: 80px;
}

.lipsum-gen {
  display: grid;
  grid-template-columns: auto 10%;
  padding: 80px 10%;
  background-color: #17203b;
}

.lipsum-gen-header {
  color: #fff;
  font-family: "Javanese Text", Georgia;
  font-size: 4rem;
}

.lipsum-gen-header-divider {
  display: block;
  width: 70%;
  border-bottom: 9px solid #2297da;
  height: 0;
  line-height: 0;
}

.lipsum-gen-copy {
  color: #fff;
  font-family: "Javanese Text", Georgia;
  font-size: 2em;
  padding: 5% 5% 5% 2%;
  letter-spacing: 0.1em;
}

.lipsum-gen-copy p {
  margin-bottom: 40px;
}

.lipsum-gen-nav {
  width: 46px;
  background-color: #17203b;
  padding: 0;
  margin: 0;
  line-height: 0;
  margin-top: 20px;
}

.lipsum-gen-nav-divider {
  display: block;
  background-image: url(../public/ipsum-gen-nav-div-bg.png);
  background-size: 46px 16px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 16px;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.lipsum-gen-nav-refresh {
  background-image: url(../public/ipsum-gen-nav-refresh-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.lipsum-gen-nav-structure {
  background-image: url(../public/ipsum-gen-nav-empty-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  text-align: center;
  margin: 0;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 30px;
}

.lipsum-gen-nav-decrement {
  background-image: url(../public/ipsum-gen-nav-decrement-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.lipsum-gen-nav-count {
  background-image: url(../public/ipsum-gen-nav-empty-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  text-align: center;
  margin: 0;
  color: #fff;
  font-family: "Roboto";
  font-weight: bold;
  font-size: 30px;
}

.lipsum-gen-nav-increment {
  background-image: url(../public/ipsum-gen-nav-increment-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.lipsum-gen-nav-copy {
  background-image: url(../public/ipsum-gen-nav-copy-bg.png);
  background-size: 46px 46px;
  background-color: #17203b;
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  border: none;
  line-height: 0;
  padding: 0;
  margin: 0;
}

.navbar-toggler-icon {
  background-image: url(../public/ipsum-nav-toggle.png) !important;
}

.navbar-dark .bg-dark-custom {
  background-color: #17203b !important;
  border: none;
}

.navbar-dark .navbar-toggler {
  color: #17203b !important;
  border-color: #17203b !important;
  width: 100% !important;
  text-align: right !important;
  padding-right: 0 !important;
  border: 0 !important;
}

.navbar-toggler:focus {
  text-decoration: none !important;
  outline: 0 !important;
  box-shadow: 0 !important;
}

.container-fluid {
  text-align: left;
  width: 240px;
}

.nav-item {
  padding-left: 5px;
  padding-right: 5px;
}

.nav-item .nav-link {
  padding-right: 10px !important;
}

.nav-item .nav-link.active {
  border-bottom: 2px solid #2297da;
}

.navbar-collapse {
  background-color: #2156af;
  width: 50%;
  float: right;
  /* border: 1px solid #17203b; */
}

@media screen and (max-width: 960px) {
  header > img {
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    width: 120px;
  }

  header {
    background-color: #17203b;
    height: 100px;
    width: 100%;
  }

  nav {
    float: right;
    margin-top: 20px;
    margin-right: 20px;
    width: 20%;
  }

  .intro h1 {
    font-size: 40px;
    color: #fff;
    font-style: italic;
    line-height: 1.7em;
  }

  .intro p {
    font-size: 28px;
  }

  .intro span {
    font-size: 22px;
  }

  .circle {
    background: #456bd9;
    clip-path: circle(50%);
    height: 5em;
    width: 5em;
  }

  .lipsum-gen {
    display: grid;
    grid-template-columns: auto 10%;
    padding: 80px 10%;
    background-color: #17203b;
  }

  .lipsum-gen-header {
    color: #fff;
    font-family: "Javanese Text", Georgia;
    font-size: 40px;
  }

  .lipsum-gen-copy {
    color: #fff;
    font-family: "Javanese Text", Georgia;
    font-size: 20px;
    padding: 5% 5% 5% 2%;
    letter-spacing: 0.1em;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
